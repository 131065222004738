
// All styles for Work Description Component

#projectDescription{
    padding: 200px 0px 150px;
    @media (max-width: 480px){
        padding: 100px 0;
    }
    @media (min-width: 2000px){
        padding: 400px 0;
    }
    h2{
        @media (max-width: 1200px){
            margin-bottom: 75px;
        }
        @media (max-width: 567px){
            margin-bottom: 50px;
        }
    }
    h5{
        color: #888;
        margin-bottom: 25px; 
    }
    .project-desc{
        padding-top: 10px;
        p.big-p{
            padding-right: 50px;
        }
    }
    .desc-bottom{
        margin-top: 55px;
        @media (max-width: 768px){
            margin-top: 10px;
            margin-bottom: 0px;
        }
        a{
            color: #aaa;
        }
        p{
            color: #888;
        }
    }
    @media (max-width:1200px){
        .desc-bottom-after-2{
            order: 4;
        }
        .desc-bottom-after-1{
            order: 3;
        }
        .desc-bottom-before-2{
            margin-top: 50px;
            margin-bottom: 50px;
            order: 2;
        }
        .desc-bottom-before-1{
            margin-top: 50px;
            margin-bottom: 50px;
            order: 1;
        }
    }
    @media (max-width: 992px){
        .desc-bottom-before-2{
            margin-top: 0px;
        }
    }
}