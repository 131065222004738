
// All styles for Work Gallery Component

#projectImages{
    padding: 150px 0px;
    @media(max-width: 768px){
        padding: 200px 0px 100px 0px;
    }
    @media (max-width: 480px){
        padding: 100px 0;
    }
    @media (min-width: 2000px){
        padding: 400px 0px;
    }
    .row{
        margin: 0 -50px;
    }
    .project-img-left{
        padding: 0 50px;
        img{
            max-width: 100%;
            max-height: 100%;
            margin-top: 100px;
            @media (max-width: 768px){
                margin-top: 0px;
                margin-bottom: 100px;
            }
        }
    }
    .project-img-right{
        padding: 0 50px;
        img{
            max-width: 100%;
            max-height: 100%;
            margin-bottom: 100px;
        }
    }
}