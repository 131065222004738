
// All styles for Header

header{
    padding: 4vw 0px 0px 0px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 999;
    background: transparent;
    @media (max-width: 768px){
        padding: 7vw 0 0 0;
    }
    @media (max-width: 480px){
        padding: 8vw 0 0 0;
    }
    .header-left{
        @media (max-width: 768px){
            margin-bottom: 20px;
        }
        @media (max-width: 480px){
            margin-bottom: 15px;
        }
        .logo{
            a{
                &:after{
                    display: none;
                }
            }
            span{
                font-weight: 400;
                letter-spacing: 1px;
                font-size: 1.2em;
                color: #fff;
                @media (max-width: 480px){
                    font-size: 1em;
                }
            }
        }
    }
    .header-right{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 6px;
        @media (max-width: 768px){
            justify-content: flex-start;
        }
        .main-menu{
            list-style: none;
            margin: 0;
            padding: 0;
            text-align: right;
        }
        li{
            display: inline-block;
            a{
                color: #aaa;
                font-weight: 400;
                margin-left: 60px;
                font-size: 1em;
                position: relative;
                transition: .25s cubic-bezier(.75,.25,.25,.75);
                @media (max-width: 768px){
                    margin-left: 0px;
                    margin-right: 30px;
                }
                @media (max-width: 480px){
                    font-size: .8em;
                }
                &:after{
                    content: "";
                    height: 1px;
                    width: 0%;
                    background: #285BD4;
                    position: absolute;
                    left: auto;
                    right: 0;
                    bottom: -3px;
                    transition: .25s cubic-bezier(.75,.25,.25,.75);
                }
                &:hover{
                    color: #fff;
                    transition: .25s cubic-bezier(.75,.25,.25,.75);
                    &:after{
                        width: 100%;
                        left: 0;
                        right: auto;
                        transition: .25s cubic-bezier(.75,.25,.25,.75);
                    }
                }
                &.active{
                    color: #fff !important;
                    outline: 0;
                    text-decoration: none;
                    transition: .25s cubic-bezier(.75,.25,.25,.75);

                    &:after{
                        width: 100%;
                        right: auto;
                        left: 0;
                        transition: .25s cubic-bezier(.75,.25,.25,.75);
                    }
                }
            }
        }
    }
}

header {
  padding: 4vw 0px 0px 0px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  background: transparent;
  @media (max-width: 768px){
      padding: 7vw 0 0 0;
  }
  @media (max-width: 480px){
      padding: 8vw 0 0 0;
  }
  .header-left{
      @media (max-width: 768px){
          margin-bottom: 20px;
      }
      @media (max-width: 480px){
          margin-bottom: 15px;
      }
      .logo{
          a{
              &:after{
                  display: none;
              }
          }
          span{
              font-weight: 400;
              letter-spacing: 1px;
              font-size: 1.2em;
              color: #fff;
              @media (max-width: 480px){
                  font-size: 1em;
              }
          }
      }
  }
    ul {
        &.main-menu {
          list-style: none;
          margin: 0;
          padding: 0;
          text-align: right;
            > li {
                margin: 0 15px;
                position: relative;
                > a {
                    color: #aaa;
                    font-weight: 400;
                    margin-left: 60px;
                    font-size: 1em;
                    position: relative;
                    transition: .25s cubic-bezier(.75,.25,.25,.75);
                    @media (max-width: 768px){
                        margin-left: 0px;
                        margin-right: 30px;
                    }
                    @media (max-width: 480px){
                        font-size: .8em;
                    }
                    &:after{
                        content: "";
                        height: 1px;
                        width: 0%;
                        background: #285BD4;
                        position: absolute;
                        left: auto;
                        right: 0;
                        bottom: -3px;
                        transition: .25s cubic-bezier(.75,.25,.25,.75);
                    }
                    &:hover{
                        color: #fff;
                        transition: .25s cubic-bezier(.75,.25,.25,.75);
                        &:after{
                            width: 100%;
                            left: 0;
                            right: auto;
                            transition: .25s cubic-bezier(.75,.25,.25,.75);
                        }
                    }
                    &.active{
                        color: #fff !important;
                        outline: 0;
                        text-decoration: none;
                        transition: .25s cubic-bezier(.75,.25,.25,.75);

                        &:after{
                            width: 100%;
                            right: auto;
                            left: 0;
                            transition: .25s cubic-bezier(.75,.25,.25,.75);
                        }
                    }
                }
                > ul {
                    &.submenu {
                        @extend %liststyle;
                        min-width: 240px;
                        // height: auto;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        z-index: 90;
                        opacity: 0;
                        visibility: hidden;
                        background-color: rgba(0,0,0,0);
                        text-align: left;
                        @extend %transition;
                        // padding: 12px 0;
                        // border-radius: 4px;
                        li {
                            a {
                                color: #aaa;
                                font-weight: 400;
                                margin-left: 60px;
                                font-size: 1em;
                                display: block;
                                color: #aaa;
                                @extend %transition;
                            }
                            &:hover {
                                a {
                                    color: $theme-color;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    > ul {
                        &.submenu {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }

                &:hover {
                    > a {
                        color: $theme-color;
                    }
                }
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}
