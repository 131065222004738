.container > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
}

.main-content {
  cursor: pointer !important;
}

.text {
  pointer-events: none;
  justify-content: start !important;
  font-family: 'Kanit', sans-serif;
  line-height: 0px;
  text-transform: uppercase;
}

.number {
  font-size: 300px;
  color: #545864;
}

.number span {
  display: inline-block;
  padding-top: 8rem;
  padding-left: 12rem;
  position: relative;
  transform: translate3d(-35%, 0, 0);
}

.header {
  display: flex;
  justify-content: center !important;
  align-items: center;
  font-size: 50px;
  color: white;
  line-height: 3rem;
}

.stripe {
  height: 2px;
  width: auto;
}

.slopeBegin {
  background-color: #20232f;
  clip-path: polygon(20% 0, 70% 0, 50% 100%, 0% 100%);
}

.slopeEnd {
  clip-path: polygon(70% 0, 100% 0, 80% 100%, 50% 100%);
}

.slopeBegin,
.slopeEnd {
  position: absolute;
  width: 170%;
  height: 100%;
  cursor: pointer;
}

.pink {
  background: linear-gradient(to right, deeppink 0%, coral 100%);
}

.teal {
  background: linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%);
}

.tomato {
  background: linear-gradient(to right, tomato 0%, gold 100%);
}
