
.Sequence {
  font-family: sans-serif;
  text-align: center;
  z-index: -99999 !important;
}

.sequence-content {
    position: absolute !important;
    top: 0px;
    width: 100%;
    max-height: 100%;
    p, h1, h2, h3 {
        color: #ffffff !important;
    }

}

.skeleton {
  /* background-color: rgba(240, 240, 240, 1);
  background-image: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1),
    rgba(240, 240, 240, 1),
    rgba(255, 255, 255, 1),
    rgba(240, 240, 240, 1),
    rgba(240, 240, 240, 1)
  ); */
  background-size: 200px 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 2px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  animation: skeleton 500ms ease-in-out infinite;

  @keyframes skeleton {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }
}

/*=============================
	Overlay styles
==============================*/

[data-overlay],
[data-black-overlay],
[data-white-overlay] {
    position: relative;
    z-index: 1;
}

[data-overlay]>div,
[data-overlay]>*,
[data-black-overlay]>div,
[data-black-overlay]>*,
[data-white-overlay]>div,
[data-white-overlay]>* {
    position: relative;
    z-index: 1;
}

[data-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}



[data-black-overlay]:before {
    background-color: #00010c;
}

[data-white-overlay]:before {
    background-color: #b4abab;
}

@for $i from 1 through 10 {
    [data-overlay="#{$i}"]:before,
    [data-black-overlay="#{$i}"]:before,
    [data-white-overlay="#{$i}"]:before {
        opacity: #{$i * 0.10};
    }
}
