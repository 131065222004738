/*==========================
    Cards Area
===========================*/

@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}

.card_wrapper {
  background-image: url('/assets/images/card_table.webp');
  margin-top: -100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: relative;
  @media #{$md-layout} {

  }
  @media #{$sm-layout} {
    margin-bottom: 300px;
  }
}

.card-arrow {
  -webkit-animation: shake-horizontal 3s linear 1s infinite alternate ;
          animation: shake-horizontal 3s linear 1s infinite alternate ;
          font-size: 2em;
          margin-left: 10px;
}

.solution-click {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
	        animation: pulsate-fwd 0.5s ease-in-out infinite both;
          font-size: 2em;
          margin-left: 10px;
}

.cards {
  margin-left: 40%;
  margin-bottom: 100px;
  overscroll-behavior-y: contain;
  box-sizing: border-box;
  user-select: none;
  position: relative;
  // overflow: hidden;
  cursor:grab;
}

.cards > div {
  margin-top: 20% !important;
  vertical-align: middle;
  position: absolute;
  will-change: transform;
  margin: auto;
  align-items: center;
  justify-content: center;
  @media #{$lg-layout} {
    margin-top: 20px;
    left: 100px;
  }
  @media #{$md-layout} {
    margin-top: 100px;
    top: 40%;
    left: 0%;
    transform: translate(-40%, -0%);
  }
  @media #{$large-mobile} {
    top: 5%;
    left: 5%;
    transform: translate(-5%, 5%);
  }
}

.cards > div > div {
  background-color: #F9F8F8;
  border-color: #F9F8F8;
  background-size: 95% 95%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-repeat: no-repeat;
  border-radius: 15px;
  background-position: center center;
  width: 240px;
  height: 336px;
  @media #{$sm-layout} {
    width: 216px;
    height: 302px;
  }
  overflow: hidden;
  will-change: transform;
  box-shadow: 0 12.5px 100px -10px rgba(0,0,0, 0.3), 0 10px 10px -10px rgba(0,0,0, 0.5);
}

.process {
  @media #{$md-layout} {

  }
}

.video-click {
  -webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
	        animation: pulsate-fwd 0.5s ease-in-out infinite both;
          font-size: 2em;
}
