
// All styles for News Article Details Page

.single-news-hero{
    height: 100vh !important;
    h6{
        color: #888;
        letter-spacing: 0px;
        font-weight: 400;
        margin-bottom: 50px !important;
        span{
            text-transform: none !important;
            margin-right: 75px;
            @media (max-width: 768px){
                display: block;
                margin-bottom: 25px;
                margin-right: 0px;
            }
        }
        a{
            color: #aaa;
            margin-right: 25px;
        }
    }
    h1{
        @media (min-width: 1200px){
            max-width: 70%;
        }
    }
    img{
        max-width: 100%;
        max-height: 100%;
    }
}

#singleNews{
    padding: 200px 0px;
    @media (max-width: 480px){
        padding: 100px 0;
    }
    img{
        max-width: 100%;
        max-height: 100%;
        margin-bottom: 75px;
    }
    .single-news-left{
        h3{
            margin-bottom: 35px;
        }
        .single-news-date{
            margin-bottom: 40px;
            font-weight: 300;
            letter-spacing: .5px;
            margin-right: 75px;
        }
        blockquote{
            margin: 35px 0px;
            padding-left: 50px;
            padding-bottom: 3px;
            border-left: 5px solid #222;
            p{
                font-size: 1.2em;
                color: #eee;
            }
            cite{
                font-size: .8em;
                color: #656565;
            }
        }
        ul{
            margin: 30px 0px;
            padding-left: 50px;
            li{
                &:before{
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    border: 0;
                    background: #454545;
                    display: inline-block;
                    position: relative;
                    right: 16px;
                    bottom: 4px;
                }
            }
        }
        .tags{
            margin-top: 75px;
            span{
                border: 1px solid #285BD4;
                padding: 7px 10px;
                border-radius: 25px;
                font-size: .7em;
                margin-right: 10px;
                color: #fff;
                cursor: pointer;
                transition: .25s cubic-bezier(.75,.25,.25,.75);
                &:hover{
                    color: #fff;
                    background: #285BD4;
                    transition: .25s cubic-bezier(.75,.25,.25,.75);
                }
            }
        }
    }
    .sidebar{
        padding-left: 75px;
        @media (max-width: 1200px){
            padding-left: 15px;
            margin-top: 150px;
        }
        .widget{
            margin-bottom: 75px;
            border: 1px solid #222;
            padding: 40px;
            h5{
                color: #888;
                margin-bottom: 30px;
            }
            .recent-post{
                margin-bottom: 30px;
                h4{
                    font-weight: 300;
                    color: #fff;
                    font-size: 1em;
                    line-height: 1.6;
                }
                .recent-date{
                    font-size: .75em;
                    color: #888;
                }
                &:last-child{
                    margin-bottom: 0px;
                }
            }
            ul{
                li{
                    line-height: 1;
                    margin-bottom: 17px;
                    &:last-child{
                        margin-bottom: 10px;
                    }
                    a{
                        color: #aaa;
                    }
                }
            }
            a{
                color: #aaa;
                margin-bottom: 15px;
            }
        }
    }
}